export const productionFirebaseCredentials = {
  apiKey: process.env.REACT_APP_PROD_API_KEY || '',
  authDomain: 'vetoquinol-app.firebaseapp.com',
  projectId: 'vetoquinol-app',
  storageBucket: 'vetoquinol-app.appspot.com',
  messagingSenderId: '776462720457',
  appId: '1:776462720457:web:ad9076d7f2b241dc3cdc18',
};

export const stagingFirebaseCredentials = {
  apiKey: process.env.REACT_APP_TEST_API_KEY || '',
  authDomain: 'vetoquinol-app-test.firebaseapp.com',
  projectId: 'vetoquinol-app-test',
  storageBucket: 'vetoquinol-app-test.appspot.com',
  messagingSenderId: '449462994159',
  appId: '1:449462994159:web:537d68d49b96d8cdf6c71a',
};
