import { EntitySchema } from '@camberi/firecms';

export interface ContactContent {
  mainText: string; // Markdown.
}

export default {
  name: 'Contact Us Content',
  properties: {
    mainText: {
      title: 'Main Text',
      validation: { required: true },
      description: 'Describe how to contact Vetoquinol for questions, concerns and feedback.',
      dataType: 'string',
      config: {
        markdown: true,
      },
    },
  },
} as EntitySchema<ContactContent>;
