import { EntitySchema } from '@camberi/firecms';

export interface EmailNotification {
  subject: string;
  toGroup: string[];
  category: string;
  markdown: string; // Markdown.
  recipientCount: number;
  dateCreated: any;
  dateUpdated: any;
}

export default {
  name: 'Email Notficiation',
  properties: {
    subject: {
      title: 'Subject',
      validation: { required: true },
      dataType: 'string',
    },
    toGroup: {
      dataType: 'array',
      title: 'To',
      validation: { required: true },
      description: 'Pick the groups you want to email',
      of: {
        dataType: 'string',
        config: {
          enumValues: {
            partner: 'Partners',
            admin: 'Admins',
            applicant: 'Applicants',
            alipesTest: 'Alipes Test',
          },
        },
      },
    },
    category: {
      title: 'Category',
      dataType: 'string',
      validation: { required: true },
      description: 'Pick the type of email. Some users may have one or both disabled.',
      config: {
        enumValues: {
          marketing: 'Marketing',
          important: 'Important',
        },
      },
    },
    markdown: {
      title: 'Body',
      dataType: 'string',
      validation: { required: true },
      config: {
        markdown: true,
      },
    },
    recipientCount: {
      title: 'Recipient Count',
      dataType: 'number',
      disabled: true,
    },
    dateCreated: {
      title: 'Date Created',
      dataType: 'timestamp',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      title: 'Date Updated',
      dataType: 'timestamp',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
  },
} as EntitySchema<EmailNotification>;
