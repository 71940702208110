import { EntitySchema } from '@camberi/firecms';

export interface EmailContent {
  approvalEmailBody: string; // Markdown.
}

export default {
  name: 'Email Content',
  properties: {
    approvalEmailBody: {
      title: 'Approval Email Body',
      validation: { required: true },
      description: `Body for this email type. If left empty a default fallback message will be used instead. Do not include a greeting in this text since one will be prepended automatically.`,
      dataType: 'string',
      config: {
        markdown: true,
      },
    },
  },
} as EntitySchema<EmailContent>;
