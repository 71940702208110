import { EntitySchema } from '@camberi/firecms';
import { Timestamp } from 'firebase/firestore';

// AIzaSyAk-0xeg57dPqWzklh5xT-gCxds5hZwKn0

export const GOOGLE_API_KEY = '';

export interface TerritoryManager {
  docId?: string;
  location: string;
  fullName: string;
  focus: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  visible: boolean;
  formattedAddress?: string;
  placeId?: string;
  latLng?: number[];
  dateCreated?: Timestamp;
  dateUpdated?: Timestamp;
}

export default {
  name: 'Territory Manager',
  properties: {
    location: {
      title: 'Location',
      dataType: 'string',
      validation: { required: true },
    },
    fullName: {
      title: 'Full Name',
      dataType: 'string',
      validation: { required: true },
    },
    focus: {
      title: 'Focus',
      dataType: 'string',
      validation: { required: true },
      config: {
        enumValues: {
          CA: 'Companion Animal',
          LA: 'Large Animal',
        },
      },
    },
    city: {
      title: 'City',
      dataType: 'string',
      validation: { required: true },
    },
    state: {
      title: 'State',
      dataType: 'string',
      validation: { required: true },
    },
    zip: {
      title: 'Zip',
      dataType: 'string',
      validation: { required: true },
    },
    phone: {
      title: 'Phone',
      dataType: 'string',
      validation: { required: true },
    },
    email: {
      title: 'Email',
      dataType: 'string',
      validation: { required: true },
    },
    visible: {
      title: 'Visible',
      dataType: 'boolean',
      description: 'Should this post be invisible?',
    },
    dateCreated: {
      title: 'Date Created',
      dataType: 'timestamp',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      title: 'Date Updated',
      dataType: 'timestamp',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
  },
} as EntitySchema<TerritoryManager>;
