import { EntityReference, EntitySchema } from '@camberi/firecms';

export enum eOrganizationTypes {
  GPO = 'GPO',
  distributor = 'distributor',
  corporation = 'corporation',
}

export interface Organization {
  name: string;
  type: eOrganizationTypes;
  hiddenLiterature: EntityReference[];
  hiddenSamples: EntityReference[];
  hiddenPromotions: EntityReference[];
  hiddenProducts: EntityReference[];
  featuredProducts: EntityReference[];
  featuredPromotions: EntityReference[];
  dateCreated: any;
  dateUpdated: any;
}

export default {
  name: 'Organization',
  properties: {
    name: {
      title: 'Name',
      validation: { required: true },
      dataType: 'string',
    },
    dateCreated: {
      title: 'Date Created',
      dataType: 'timestamp',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      title: 'Date Updated',
      dataType: 'timestamp',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
    hiddenLiterature: {
      dataType: 'array',
      title: 'Hidden Literature',
      of: {
        dataType: 'reference',
        path: 'literature',
      },
    },
    hiddenSamples: {
      dataType: 'array',
      title: 'Hidden Samples',
      of: {
        dataType: 'reference',
        path: 'samples',
      },
    },    
    hiddenPromotions: {
      dataType: 'array',
      title: 'Hidden Promotions',
      of: {
        dataType: 'reference',
        path: 'promotions',
      },
    },
    featuredPromotions: {
      dataType: 'array',
      title: 'Featured Promotions',
      of: {
        dataType: 'reference',
        path: 'promotions',
      },
    },
    hiddenProducts: {
      dataType: 'array',
      title: 'Hidden Products',
      of: {
        dataType: 'reference',
        path: 'products',
      },
    },
    featuredProducts: {
      dataType: 'array',
      title: 'Featured Products',
      of: {
        dataType: 'reference',
        path: 'products',
      },
    },
  },
} as EntitySchema<Organization>;
