import { EntitySchema } from '@camberi/firecms';

export interface AboutContent {
  mainText: string; // Markdown.
}

export default {
  name: 'About Us Content',
  properties: {
    mainText: {
      title: 'Main Text',
      validation: { required: true },
      description: `Describe Vetoquinol and what it's about.`,
      dataType: 'string',
      config: {
        markdown: true,
      },
    },
  },
} as EntitySchema<AboutContent>;
