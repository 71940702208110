//@ts-nocheck

import React from 'react';
import logo from './connectLogo.svg';
import { addDoc, collection, doc, DocumentData, Firestore, FirestoreSettings, getDoc, getDocs, getFirestore, initializeFirestore, Query, query, setDoc, Timestamp, updateDoc, where } from 'firebase/firestore';
import { Authenticator, buildCollection, buildEntityCallbacks, buildSchema, EntityOnSaveProps, FirebaseCMSApp, NavigationBuilder, NavigationBuilderProps } from '@camberi/firecms';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import 'typeface-rubik';
import 'typeface-space-mono';
import { productionFirebaseCredentials, stagingFirebaseCredentials } from './firebase-credentials';
import { EmailAuthProvider } from 'firebase/auth';
import styled from '@emotion/styled';
import { FirebaseApp, initializeApp } from '@firebase/app';
import blogPostSchemaObj, { BlogPost } from './schema/blogPostSchemaObj';
import productSchemaObj, { Product } from './schema/productSchemaObj';
import organizationSchemaObj, { Organization } from './schema/organizationSchemaObj';
import literatureSchemaObj, { Literature } from './schema/literatureSchemaObj';
import sampleSchemaObj, { Sample } from './schema/sampleSchemaObj';
import clinicalSolutionSchemaObj, { ClinicalSolution } from './schema/clinicalSolutionSchemaObj';
import continuingEducationSchemaObj, { ContinuingEducation } from './schema/continuingEducationSchemaObj';
import promotionSchemaObj, { Promotion } from './schema/promotionSchemaObj';
import userSchemaObj, { PlatformUser } from './schema/userSchemaObj';
import contactContentSchemaObj, { ContactContent } from './schema/contactContentSchemaObj';
import aboutContentSchemaObj, { AboutContent } from './schema/aboutContentSchemaObj';
import faqContentSchemaObj, { FaqContent } from './schema/faqContentSchemaObj';
import territoryManagerSchemaObj, { TerritoryManager } from './schema/territoryManagerSchemaObj';
import literatureOrderSchemaObj, { DetailedAddress, LiteratureOrder } from './schema/literatureOrderSchemaObj';
import sampleOrderSchemaObj, { SampleOrder } from './schema/sampleOrderSchemaObj';
import emailNotificationSchemaObj, { EmailNotification } from './schema/emailNotificationSchemaObj';
import smsNotificationSchemaObj, { SmsNotification } from './schema/smsNotificationSchemaObj';
import emailContentSchemaObj, { EmailContent } from './schema/emailContentSchemaObj';

import { Loader } from '@googlemaps/js-api-loader';
import { marked } from 'marked';
import Actions from './Actions';

// TODO: Replace with your config
let firebaseConfig = {};

export const isProductionEnv = process.env.NODE_ENV === 'production' && window.location.hostname.includes('cms.vqconnect.app');
export let currFirestore: Firestore;
export let firebaseApp: FirebaseApp;
export let firebaseCredentials: {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
};

if (isProductionEnv) {
  const fireStoreSettings: FirestoreSettings = { ignoreUndefinedProperties: true };
  firebaseCredentials = productionFirebaseCredentials;
  firebaseApp = initializeApp(productionFirebaseCredentials);
  currFirestore = initializeFirestore(firebaseApp, fireStoreSettings);
  firebaseConfig = {
    ...productionFirebaseCredentials,
  };
} else {
  firebaseCredentials = stagingFirebaseCredentials;
  firebaseApp = initializeApp(stagingFirebaseCredentials);
  currFirestore = getFirestore(firebaseApp);
  firebaseConfig = {
    ...stagingFirebaseCredentials,
  };
}

const CustomFilePreview = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
`;

export function CustomFilePreviewFunc({ value }: any): any {
  return <CustomFilePreview>{value.split('/')[1]}</CustomFilePreview>;
}

export function DeliveryAddressPreviewFunc({ value }: any): any {
  const deliveryAddress = value as DetailedAddress;
  return deliveryAddress.addressLine2 ? <CustomFilePreview>{`${deliveryAddress.addressLine2}, ${deliveryAddress.formattedAddress}`}</CustomFilePreview> : <CustomFilePreview>{deliveryAddress.formattedAddress}</CustomFilePreview>;
}

const organizationSchema = buildSchema<Organization>(organizationSchemaObj);
const blogPostSchema = buildSchema<BlogPost>(blogPostSchemaObj);
const productSchema = buildSchema<Product>(productSchemaObj);
const literatureSchema = buildSchema<Literature>(literatureSchemaObj);
const sampleSchema = buildSchema<Sample>(sampleSchemaObj);
const promotionSchema = buildSchema<Promotion>(promotionSchemaObj);
const userSchema = buildSchema<PlatformUser>(userSchemaObj);
const contactContentSchema = buildSchema<ContactContent>(contactContentSchemaObj);
const aboutContentSchema = buildSchema<AboutContent>(aboutContentSchemaObj);
const faqContentSchema = buildSchema<FaqContent>(faqContentSchemaObj);
const territoryManagerSchema = buildSchema<TerritoryManager>(territoryManagerSchemaObj);
const literatureOrderSchema = buildSchema<LiteratureOrder>(literatureOrderSchemaObj);
const sampleOrderSchema = buildSchema<SampleOrder>(sampleOrderSchemaObj);
const clinicalSolutionSchema = buildSchema<ClinicalSolution>(clinicalSolutionSchemaObj);
const continuingEducationSchema = buildSchema<ContinuingEducation>(continuingEducationSchemaObj);
const emailNotificationSchema = buildSchema<EmailNotification>(emailNotificationSchemaObj);
const smsNotificationSchema = buildSchema<SmsNotification>(smsNotificationSchemaObj);
const emailContentSchema = buildSchema<EmailContent>(emailContentSchemaObj);

let oldImage: string = '';
let oldFilesArray: string[] | undefined = undefined;
let oldImagesArray: string[] | undefined = undefined;
let oldLogo: string = '';

const preDeleteFuncForItemsWithCommonImagesAndAndFilesProperties = (collection: string) => {
  return async ({ entityId }: { entityId: string }) => {
    let filesToDeleteFromStorage: string[] = [];
    let imageToDeleteFromStorage: string[] = [];
    let imagesToDeleteFromStorage: string[] = [];
    let logoToDeleteFromStorage: string[] = [];
    await getDoc(doc(currFirestore, collection, entityId)).then((docSnap) => {
      if (docSnap.exists()) {
        const oldItem = docSnap.data() as any;
        imageToDeleteFromStorage = oldItem.image ? [oldItem.image] : [];
        logoToDeleteFromStorage = oldItem.logo ? [oldItem.logo] : [];
        // Only products have
        filesToDeleteFromStorage = [...(oldItem.files || []), ...(oldItem.productInsertFiles || []), ...(oldItem.importantSafetyInformation || []), ...(oldItem.miscFiles || [])];
        imagesToDeleteFromStorage = oldItem.images ? [...oldItem.images] : [];
      }
    });
    deleteFilesFromStorage([...filesToDeleteFromStorage, ...imagesToDeleteFromStorage, ...imageToDeleteFromStorage, ...logoToDeleteFromStorage], entityId).catch((error) => {
      console.error(`Could not delete files associated with the ${collection}: ${error?.message}`);
      throw error;
    });
  };
};

const preSaveFuncForItemsWithCommonImagesAndAndFilesProperties = (collection: string) => {
  return async ({ entityId, values }: any) => {
    if (!entityId) {
      return values;
    }
    await getDoc(doc(currFirestore, collection, entityId)).then((docSnap) => {
      if (docSnap.exists()) {
        const oldItem = docSnap.data() as any;
        oldImage = oldItem.image || '';
        oldLogo = oldItem.logo || '';
        oldImagesArray = oldItem.images ? [...oldItem.images] : [];
        oldFilesArray = [...(oldItem.files || [])];
      }
    });
    return values;
  };
};

const onSaveFuncForItemsWithCommonImagesAndAndFilesProperties = (collection: string) => {
  return (props: EntityOnSaveProps<any>) => {
    const newImage = props.values.image || '';
    const newLogo = props.values.logo || '';
    const newImagesArray = props.values.images || [];
    const newFilesArray = [...(props.values.files || []), ...(props.values.productInsertFiles || []), ...(props.values.importantSafetyInformation || []), ...(props.values.miscFiles || [])];
    // Only delete the old image if one exists and it's not the same as whatever the new image is.
    const imageToDeleteFromStorage = newImage !== oldImage && oldImage ? [oldImage] : [];
    const logoToDeleteFromStorage = newLogo !== oldLogo && oldLogo ? [oldLogo] : [];
    const filesToDeleteFromStorage =
      oldFilesArray?.filter((f) => {
        return !newFilesArray?.includes(f);
      }) || [];
    const imagesToDeleteFromStorage =
      oldImagesArray?.filter((f) => {
        return !newImagesArray?.includes(f);
      }) || [];
    deleteFilesFromStorage([...filesToDeleteFromStorage, ...imagesToDeleteFromStorage, ...imageToDeleteFromStorage, ...logoToDeleteFromStorage], props.entityId || '').catch((error) => {
      console.error(`Could not delete files associated with the ${collection}: ${error?.message}`);
    });
  };
};

const blogPostCallbacks = buildEntityCallbacks({
  onPreSave: preSaveFuncForItemsWithCommonImagesAndAndFilesProperties('blogPosts'),
  onPreDelete: preDeleteFuncForItemsWithCommonImagesAndAndFilesProperties('blogPosts'),
  onSaveSuccess: onSaveFuncForItemsWithCommonImagesAndAndFilesProperties('blogPosts'),
});

const promotionCallbacks = buildEntityCallbacks({
  onPreSave: preSaveFuncForItemsWithCommonImagesAndAndFilesProperties('promotions'),
  onPreDelete: preDeleteFuncForItemsWithCommonImagesAndAndFilesProperties('promotions'),
  onSaveSuccess: onSaveFuncForItemsWithCommonImagesAndAndFilesProperties('promotions'),
});

const literatureCallbacks = buildEntityCallbacks({
  onPreSave: preSaveFuncForItemsWithCommonImagesAndAndFilesProperties('literature'),
  onPreDelete: preDeleteFuncForItemsWithCommonImagesAndAndFilesProperties('literature'),
  onSaveSuccess: onSaveFuncForItemsWithCommonImagesAndAndFilesProperties('literature'),
});

const sampleCallbacks = buildEntityCallbacks({
  onPreSave: preSaveFuncForItemsWithCommonImagesAndAndFilesProperties('samples'),
  onPreDelete: preDeleteFuncForItemsWithCommonImagesAndAndFilesProperties('samples'),
  onSaveSuccess: onSaveFuncForItemsWithCommonImagesAndAndFilesProperties('samples'),
});

const clinicalSolutionCallbacks = buildEntityCallbacks({
  onPreSave: preSaveFuncForItemsWithCommonImagesAndAndFilesProperties('clinicalSolutions'),
  onPreDelete: preDeleteFuncForItemsWithCommonImagesAndAndFilesProperties('clinicalSolutions'),
  onSaveSuccess: onSaveFuncForItemsWithCommonImagesAndAndFilesProperties('clinicalSolutions'),
});

const continuingEducationCallbacks = buildEntityCallbacks({
  onPreSave: preSaveFuncForItemsWithCommonImagesAndAndFilesProperties('continuingEducation'),
  onPreDelete: preDeleteFuncForItemsWithCommonImagesAndAndFilesProperties('continuingEducation'),
  onSaveSuccess: onSaveFuncForItemsWithCommonImagesAndAndFilesProperties('continuingEducation'),
});

const productCallbacks = buildEntityCallbacks({
  onPreSave: preSaveFuncForItemsWithCommonImagesAndAndFilesProperties('products'),
  onPreDelete: preDeleteFuncForItemsWithCommonImagesAndAndFilesProperties('products'),
  onSaveSuccess: onSaveFuncForItemsWithCommonImagesAndAndFilesProperties('products'),
});

const territoryManagerCallbacks = buildEntityCallbacks({
  onSaveSuccess: (props: EntityOnSaveProps<TerritoryManager>) => {
    const loader = new Loader({
      apiKey: firebaseCredentials.apiKey,
      version: 'weekly',
    });
    loader.load().then((google) => {
      const geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({
          componentRestrictions: {
            country: 'US',
            postalCode: props.values.zip,
          },
        })
        .then((res: any) => {
          const result = res?.results?.[0];
          if (result) {
            const update = {
              formattedAddress: result.formatted_address,
              placeId: result.place_id,
              latLng: [result.geometry.location.lat(), result.geometry.location.lng()],
            };
            updateDoc(doc(currFirestore, 'territoryManagers', props.entityId || ''), update).catch((err) => {
              console.error(`Could not update the territory manager with lat lng values: ${err?.message}`);
            });
          }
        })
        .catch((err: any) => {
          console.error(`Could not update the territory manager with lat lng values: ${err?.message}`);
        });
    });
  },
});

const mdToHtml = (md: string, removeHtml = false) => {
  if (!mdToHtml) {
    return '';
  }
  if (removeHtml) {
    // We need to do this so that line clamp works on Firefox.
    return marked.parse(md || '').replace(/<\/?[^>]+(>|$)/g, '');
  }
  return marked.parse(md || '');
};

const emailNotificationsCallbacks = buildEntityCallbacks({
  onPreSave: async ({ values }: { entityId?: string; values: any }) => {
    let categoryQueryPart = where('marketingEmailNotificationsEnabled', '!=', false);
    switch (values.category) {
      case 'marketing':
        categoryQueryPart = where('marketingEmailNotificationsEnabled', '!=', false);
        break;
      case 'important':
        categoryQueryPart = where('importantEmailNotificationsEnabled', '!=', false);
        break;
    }

    const toArray: string[] = [];
    let q: null | Query<DocumentData> = null;

    if (values.toGroup.includes('alipesTest')) {
      q = query(collection(currFirestore, 'users'), where('email', 'in', ['nickf@alipes.com', 'keith@alipes.com', 'danz@alipes.com']), categoryQueryPart);
    } else {
      q = query(collection(currFirestore, 'users'), where('role', 'in', values.toGroup), categoryQueryPart);
    }

    await getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((docSnap) => {
        if (docSnap.exists()) {
          const user = docSnap.data() as PlatformUser;
          toArray.push(user.email);
        }
      });
    });

    values.to = toArray;
    values.recipientCount = toArray.length;
    values.dateCreated = Timestamp.fromMillis(Date.now());
    values.dateUpdated = Timestamp.fromMillis(Date.now());
    toArray.forEach((email) => {
      addDoc(collection(currFirestore, 'sendGridMail'), {
        to: email,
        message: {
          subject: values.subject || '',
          html: mdToHtml(values.markdown) + `<br><p>If you wish to unsubscribe from these emails you can change your email preferences here: <a target="_blank" href="https://www.vqconnect.app/Settings">https://www.vqconnect.app/Settings</a>.</p>` || '',
        },
      });
    });
    return values;
  },
});

const smsNotificationsCallbacks = buildEntityCallbacks({
  onPreSave: async ({ values }: { entityId?: string; values: any }) => {
    let categoryQueryPart = where('marketingSmsNotificationsEnabled', '!=', false);
    switch (values.category) {
      case 'marketing':
        categoryQueryPart = where('marketingSmsNotificationsEnabled', '!=', false);
        break;
      case 'important':
        categoryQueryPart = where('importantSmsNotificationsEnabled', '!=', false);
        break;
    }

    const toArray: string[] = [];
    let q: null | Query<DocumentData> = null;

    if (values.toGroup.includes('alipesTest')) {
      q = query(collection(currFirestore, 'users'), where('email', 'in', ['nickf@alipes.com']), categoryQueryPart);
    } else {
      q = query(collection(currFirestore, 'users'), where('role', 'in', values.toGroup), categoryQueryPart);
    }

    await getDocs(q).then((querySnapshot) => {
      querySnapshot.forEach((docSnap) => {
        if (docSnap.exists()) {
          const user = docSnap.data() as PlatformUser;
          const phoneNumber = ('' + user.phone).replace(/[^0-9.+]/g, '');
          if (phoneNumber) {
            toArray.push(phoneNumber);
          }
        }
      });
    });

    values.to = toArray;
    values.recipientCount = toArray.length;
    values.dateCreated = Timestamp.fromMillis(Date.now());
    values.dateUpdated = Timestamp.fromMillis(Date.now());
    toArray.forEach((phone) => {
      addDoc(collection(currFirestore, 'twilioMessages'), {
        to: phone,
        body: values.body + ` To stop receiving these texts, change your sms preferences here: https://www.vqconnect.app/Settings` || '',
      });
    });
    return values;
  },
});

const fbStorage = getStorage();

function deleteFilesFromStorage(paths: string[], entityId: string): Promise<void[]> {
  const deletionPromises: Promise<void>[] = [];

  paths.forEach((path) => {
    const refsToDelete = [ref(fbStorage, path)];
    if (path.includes('dynamicImages')) {
      const containingFolder = path.split('/')[0];
      const fileName = path.split('/')[1].split('.')[0];
      refsToDelete.push(ref(fbStorage, `/${containingFolder}/resized/${fileName}_600x600.png`));
      refsToDelete.push(ref(fbStorage, `/${containingFolder}/resized/${fileName}_150x150.png`));
    }

    // Only delete this object if there are no other references to it.
    let anotherReferenceToObjectExists = false;

    function checkForRefsToFileWeAreDeletingInCollection(collectionName: string): Promise<void> {
      return new Promise((resolve) => {
        if (anotherReferenceToObjectExists) {
          resolve();
        } else {
          getDocs(collection(currFirestore, collectionName)).then((querySnap) => {
            querySnap.forEach((docSnap) => {
              if (docSnap.id !== entityId) {
                const product = docSnap.data() as any;
                if (product.image === path || product.logo === path || product.images?.includes(path) || product.files?.includes(path)) {
                  anotherReferenceToObjectExists = true;
                }
              }
            });
            resolve();
          });
        }
      });
    }

    deletionPromises.push(
      Promise.all([
        checkForRefsToFileWeAreDeletingInCollection('products'),
        checkForRefsToFileWeAreDeletingInCollection('blogPosts'),
        checkForRefsToFileWeAreDeletingInCollection('promotions'),
        checkForRefsToFileWeAreDeletingInCollection('literature'),
        checkForRefsToFileWeAreDeletingInCollection('samples'),
      ]).then(() => {
        if (!anotherReferenceToObjectExists) {
          // If no other references delete the file and any resized versions if it's a dynamic image.
          refsToDelete.forEach((fileRef) => {
            deleteObject(fileRef);
          });
        }
      })
    );
  });

  return Promise.all(deletionPromises);
}

export default function App() {
  const navigation: NavigationBuilder = async ({ user, authController }: NavigationBuilderProps) => {
    return {
      collections: [
        buildCollection({
          group: 'Collections',
          initialSort: ['dateUpdated', 'desc'],
          path: 'organizations',
          schema: organizationSchema,
          name: 'Organizations',
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: authController.extra.roles.includes('admin'),
            delete: authController.extra.roles.includes('admin'),
          }),
        }),
        buildCollection({
          group: 'Collections',
          initialSort: ['dateUpdated', 'desc'],
          path: 'products',
          schema: productSchema,
          name: 'Products',
          callbacks: productCallbacks,
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: authController.extra.roles.includes('admin'),
            delete: authController.extra.roles.includes('admin'),
          }),
        }),
        buildCollection({
          group: 'Collections',
          initialSort: ['dateUpdated', 'desc'],
          path: 'promotions',
          schema: promotionSchema,
          name: 'Promotions',
          callbacks: promotionCallbacks,
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: authController.extra.roles.includes('admin'),
            delete: authController.extra.roles.includes('admin'),
          }),
        }),
        buildCollection({
          group: 'Collections',
          initialSort: ['dateUpdated', 'desc'],
          path: 'blogPosts',
          schema: blogPostSchema,
          name: 'Blog Posts',
          callbacks: blogPostCallbacks,
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: authController.extra.roles.includes('admin'),
            delete: authController.extra.roles.includes('admin'),
          }),
        }),
        buildCollection({
          group: 'Collections',
          initialSort: ['dateUpdated', 'desc'],
          path: 'literature',
          schema: literatureSchema,
          name: 'Literature',
          callbacks: literatureCallbacks,
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: authController.extra.roles.includes('admin'),
            delete: authController.extra.roles.includes('admin'),
          }),
        }),
        buildCollection({
          group: 'Collections',
          initialSort: ['dateUpdated', 'desc'],
          path: 'samples',
          schema: sampleSchema,
          name: 'Samples',
          callbacks: sampleCallbacks,
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: authController.extra.roles.includes('admin'),
            delete: authController.extra.roles.includes('admin'),
          }),
        }),
        buildCollection({
          group: 'Collections',
          initialSort: ['dateUpdated', 'desc'],
          path: 'clinicalSolutions',
          schema: clinicalSolutionSchema,
          name: 'Clinical Solutions',
          callbacks: clinicalSolutionCallbacks,
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: authController.extra.roles.includes('admin'),
            delete: authController.extra.roles.includes('admin'),
          }),
        }),
        buildCollection({
          group: 'Collections',
          initialSort: ['dateUpdated', 'desc'],
          path: 'continuingEducation',
          schema: continuingEducationSchema,
          name: 'Continuing Education',
          callbacks: continuingEducationCallbacks,
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: authController.extra.roles.includes('admin'),
            delete: authController.extra.roles.includes('admin'),
          }),
        }),
        buildCollection({
          group: 'Pages',
          path: 'contactContent',
          schema: contactContentSchema,
          name: 'Contact Us Content',
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: false,
            delete: false,
          }),
        }),
        buildCollection({
          group: 'Pages',
          path: 'aboutContent',
          schema: aboutContentSchema,
          name: 'About Us Content',
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: false,
            delete: false,
          }),
        }),
        buildCollection({
          group: 'Pages',
          path: 'faqContent',
          schema: faqContentSchema,
          name: 'Faq Content',
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: false,
            delete: false,
          }),
        }),
        buildCollection({
          group: 'People',
          initialSort: ['dateUpdated', 'desc'],
          path: 'users',
          schema: userSchema,
          name: 'Users',
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            delete: authController.extra.roles.includes('admin'),
            create: false,
          }),
        }),
        buildCollection({
          group: 'People',
          initialSort: ['dateUpdated', 'desc'],
          path: 'territoryManagers',
          schema: territoryManagerSchema,
          name: 'Territory Managers',
          callbacks: territoryManagerCallbacks,
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: authController.extra.roles.includes('admin'),
            delete: authController.extra.roles.includes('admin'),
          }),
        }),
        buildCollection({
          group: 'Orders',
          initialSort: ['dateUpdated', 'desc'],
          path: 'literatureOrders',
          schema: literatureOrderSchema,
          name: 'Literature Orders',
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: authController.extra.roles.includes('admin'),
            delete: authController.extra.roles.includes('admin'),
          }),
        }),
        buildCollection({
          group: 'Orders',
          initialSort: ['dateUpdated', 'desc'],
          path: 'sampleOrders',
          schema: sampleOrderSchema,
          name: 'Sample Orders',
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: authController.extra.roles.includes('admin'),
            delete: authController.extra.roles.includes('admin'),
          }),
        }),
        buildCollection({
          group: 'Notifications',
          initialSort: ['dateUpdated', 'desc'],
          path: 'sendGridMailTriggers',
          schema: emailNotificationSchema,
          name: 'Email Notifications',
          callbacks: emailNotificationsCallbacks,
          permissions: ({ authController }) => ({
            create: authController.extra.roles.includes('admin'),
            delete: authController.extra.roles.includes('admin'),
          }),
        }),
        buildCollection({
          group: 'Notifications',
          initialSort: ['dateUpdated', 'desc'],
          path: 'twilioMessageTriggers',
          schema: smsNotificationSchema,
          name: 'SMS Notifications',
          callbacks: smsNotificationsCallbacks,
          permissions: ({ authController }) => ({
            create: authController.extra.roles.includes('admin'),
            delete: authController.extra.roles.includes('admin'),
          }),
        }),
        buildCollection({
          group: 'Email Content',
          path: 'emailContent',
          schema: emailContentSchema,
          name: 'Email Content',
          permissions: ({ authController }) => ({
            edit: authController.extra.roles.includes('admin'),
            create: false,
            delete: false,
          }),
        }),
      ],
    };
  };

  const myAuthenticator: Authenticator = async ({ user, authController }) => {
    if (!user) {
      return false;
    }

    let role = 'applicant';

    enum eUserRoles {
      admin = 'admin',
      applicant = 'applicant',
      partner = 'partner',
    }

    enum eThemes {
      dark = 'dark',
      light = 'light',
    }

    const docSnap = await getDoc(doc(currFirestore, 'users', user.uid));

    if (docSnap.exists()) {
      const userData = docSnap.data() as PlatformUser;
      role = userData.role as eUserRoles;

      if (role !== eUserRoles.admin) {
        return false;
      } else {
        return onUserAcquired();
      }
    } else {
      const newUserData: PlatformUser = {
        docId: user.uid,
        role: eUserRoles.applicant,
        displayName: user.displayName || '',
        companyName: '',
        phone: '',
        email: user.email || '',
        webPushTokens: [],
        dateCreated: Timestamp.fromMillis(Date.now()),
        dateUpdated: Timestamp.fromMillis(Date.now()),
        marketingEmailNotificationsEnabled: true,
        marketingSmsNotificationsEnabled: true,
        importantEmailNotificationsEnabled: true,
        importantSmsNotificationsEnabled: true,
        appTheme: eThemes.light,
        adminSelectedOrganizationEntities: [],
      };
      await setDoc(doc(currFirestore, 'users', user.uid), newUserData).catch((err) => {
        console.error(err);
      });

      window.location.replace('https://vqconnect.app');

      return onUserAcquired();
    }

    async function onUserAcquired() {
      // This is an example of retrieving async data related to the user
      // and storing it in the user extra field.
      const sampleUserData = await Promise.resolve({
        roles: [role],
      });

      authController.setExtra(sampleUserData);
      return true;
    }
  };

  const DEFAULT_SIGN_IN_OPTIONS = [EmailAuthProvider.PROVIDER_ID];

  const additionalViews = [
    {
      // group: 'Misc',
      path: '/actions',
      name: 'Actions',
      description: 'This is an example of an additional view that is defined by the user',
      view: <Actions />,
    },
  ];

  return <FirebaseCMSApp name={'VQ Connect CMS'} logo={logo} signInOptions={DEFAULT_SIGN_IN_OPTIONS} authentication={myAuthenticator} navigation={navigation} firebaseConfig={firebaseConfig} views={additionalViews} />;
}
