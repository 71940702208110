import { EntityReference, EntitySchema } from '@camberi/firecms';
import { CustomFilePreviewFunc } from '../App';

export interface BlogPost {
  title: string;
  author: string;
  blurb: string; // Markdown.
  // contentReference: EntityReference; // Markdown.
  content: string; // Markdown.
  image: string;
  goLiveDate: any;
  utcOffset?: number;
  vimeoVideoUrls: string[]; // Vimeo urls.
  files: string[]; // File urls.
  tags: string[];
  dateCreated: any;
  dateUpdated: any;
  visible: boolean;
  featured: boolean;
  isNew: boolean;
  relatedProducts: EntityReference[];
}

export default {
  name: 'Blog Post',
  properties: {
    title: {
      title: 'Title',
      validation: { required: true },
      dataType: 'string',
    },
    author: {
      title: 'Author',
      dataType: 'string',
    },
    blurb: {
      title: 'Blurb',
      validation: { required: true },
      description: 'A short description of what this post is about.',
      dataType: 'string',
    },
    content: {
      title: 'Content',
      validation: { required: true },
      description: 'Used to populate Blog Posts with Content.',
      dataType: 'string',
      config: {
        markdown: true,
      },
    },
    image: {
      title: 'Image',
      description: 'An optional picture to associate with the post.',
      dataType: 'string',
      config: {
        storageMeta: {
          mediaType: 'image',
          storagePath: 'dynamicImages',
          acceptedFiles: ['image/*'],
          fileName: (context) => {
            return context.file.name;
          },
        },
      },
    },
    goLiveDate: {
      title: 'Go Live Date',
      dataType: 'timestamp',
      description: 'When should this post be publically visible?',
      validation: { required: true },
    },
    vimeoVideoUrls: {
      dataType: 'array',
      title: 'Vimeo Videos',
      description: 'All links included here must link to vimeo videos in a format like this: https://vimeo.com/264055977',
      of: {
        dataType: 'string',
        validation: {
          matches: /https:\/\/vimeo\.com\/.*/s,
        },
      },
    },
    files: {
      dataType: 'array',
      title: 'Files',
      description: 'The shareable files associated with this post (brochures, images, other marketing materials etc.)',
      of: {
        dataType: 'string',
        config: {
          Preview: CustomFilePreviewFunc,
          storageMeta: {
            storagePath: 'productFiles',
            fileName: (context) => {
              return context.file.name;
            },
          },
        },
      },
    },
    tags: {
      dataType: 'array',
      title: 'Tags',
      description: 'Used for categorization of the post',
      of: {
        dataType: 'string',
        config: {
          enumValues: {
            product: 'Product',
            financial: 'Financial',
            award: 'Award',
            misc: 'Misc',
          },
        },
      },
    },
    visible: {
      title: 'Visible',
      dataType: 'boolean',
      description: 'Should this post be invisible?',
    },
    featured: {
      title: 'Featured',
      dataType: 'boolean',
      description: 'Should this product be featured on the dashboard?',
    },
    relatedProducts: {
      dataType: 'array',
      title: 'Related Products',
      of: {
        dataType: 'reference',
        path: 'products',
      },
    },
    isNew: {
      title: 'Is New',
      dataType: 'boolean',
      description: 'Advertise this item with a "New!" tag?',
    },
    dateCreated: {
      title: 'Date Created',
      dataType: 'timestamp',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      title: 'Date Updated',
      dataType: 'timestamp',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
  },
} as EntitySchema<BlogPost>;
