import { EntitySchema } from '@camberi/firecms';
import { DeliveryAddressPreviewFunc } from '../App';
import { DetailedAddress } from '../types';

export interface SampleOrder {
  email: string;
  companyName: string;
  phone: string;
  userName: string;
  deliveryAddress: DetailedAddress;
  orderItems: string[];
  dateCreated: any;
  dateUpdated: any;
}

export default {
  name: 'Sample Order',
  properties: {
    email: {
      title: 'Email',
      dataType: 'string',
    },
    phone: {
      title: 'Phone',
      dataType: 'string',
    },
    userName: {
      title: 'Name',
      dataType: 'string',
    },
    companyName: {
      title: 'Company Name',
      dataType: 'string',
    },
    deliveryAddress: {
      title: 'Delivery Address',
      dataType: 'map',
      config: {
        Preview: DeliveryAddressPreviewFunc,
      },
      disabled: true,
    },
    orderItems: {
      dataType: 'array',
      title: 'Ordered Sample',
      description: 'Used for categorization of the product',
      of: {
        dataType: 'string',
      },
    },
    dateCreated: {
      title: 'Date Created',
      dataType: 'timestamp',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      title: 'Date Updated',
      dataType: 'timestamp',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
  },
} as EntitySchema<SampleOrder>;
