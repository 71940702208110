import { EntitySchema } from '@camberi/firecms';

export interface QuestionAnswer {
  question: string;
  answer: string;
}

export interface FaqContent {
  mainText: string;
  questionsAndAnswers: QuestionAnswer[]; // Markdown.
}

export default {
  name: 'FAQ Content',
  properties: {
    mainText: {
      title: 'Main Text',
      description: `Optional text to display at the top of the FAQ page.`,
      dataType: 'string',
      config: {
        markdown: true,
      },
    },
    questionsAndAnswers: {
      dataType: 'array',
      title: 'Questions and Answers',
      validation: { required: true },
      description: 'Used to populate the contact page.',
      of: {
        dataType: 'map',
        properties: {
          question: {
            title: 'Question',
            description: 'The question to answer.',
            validation: { required: true },
            dataType: 'string',
          },
          answer: {
            title: 'Answer',
            description: 'The answer to the question.',
            validation: { required: true },
            dataType: 'string',
            config: {
              markdown: true,
            },
          },
        },
      },
    },
  },
} as EntitySchema<FaqContent>;
