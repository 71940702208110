import { EntityReference, EntitySchema } from '@camberi/firecms';
import { Timestamp } from 'firebase/firestore';

export enum eUserRoles {
  admin = 'admin',
  applicant = 'applicant',
  partner = 'partner',
}

export enum eThemes {
  dark = 'dark',
  light = 'light',
}

export interface PlatformUser {
  docId?: string;
  role: eUserRoles;
  displayName: string;
  companyName: string;
  phone: string;
  email: string;
  marketingEmailNotificationsEnabled: boolean;
  marketingSmsNotificationsEnabled: boolean;
  importantEmailNotificationsEnabled: boolean;
  importantSmsNotificationsEnabled: boolean;
  appTheme?: eThemes;
  webPushTokens?: string[];
  adminSelectedOrganizationEntities: EntityReference[];
  dateCreated?: Timestamp;
  dateUpdated?: Timestamp;
}

export default {
  name: 'User',
  properties: {
    displayName: {
      title: 'Name',
      dataType: 'string',
      validation: { required: true },
    },
    companyName: {
      title: 'Company Name',
      dataType: 'string',
      validation: { required: true },
    },
    phone: {
      title: 'Phone',
      dataType: 'string',
      validation: { required: true },
    },
    email: {
      title: 'Email',
      dataType: 'string',
      validation: { required: true },
    },
    role: {
      dataType: 'string',
      title: 'Role',
      description: 'Dictates what the user can see and do on the app. Admins can login here and make changes on the CMS. Both partners and admins can access the portal. And applicants must have their role changed to either admin or partner to do anything.',
      config: {
        enumValues: {
          admin: 'Admin',
          applicant: 'Applicant',
          partner: 'Partner',
        },
      },
    },
    adminSelectedOrganizationEntities: {
      dataType: 'array',
      title: 'Associated organizations',
      of: {
        dataType: 'reference',
        path: 'organizations',
      },
    },
    marketingEmailNotificationsEnabled: {
      title: 'Marketing Email Notifications',
      dataType: 'boolean',
      disabled: {
        hidden: false,
      },
    },
    importantEmailNotificationsEnabled: {
      title: 'Important Email Notifications',
      dataType: 'boolean',
      disabled: {
        hidden: false,
      },
    },
    marketingSmsNotificationsEnabled: {
      title: 'Marketing SMS Notifications',
      dataType: 'boolean',
      disabled: {
        hidden: false,
      },
    },
    importantSmsNotificationsEnabled: {
      title: 'Important SMS Notifications',
      dataType: 'boolean',
      disabled: {
        hidden: false,
      },
    },
    dateCreated: {
      title: 'Date Created',
      dataType: 'timestamp',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      title: 'Date Updated',
      dataType: 'timestamp',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
  },
} as EntitySchema<PlatformUser>;
