import React from 'react';
import './Actions.css';
// import { Button } from '@mui/material';
// import { collection, getDocs, updateDoc, Timestamp } from 'firebase/firestore';
// import { currFirestore } from './App';
// import { PlatformUser } from './collections/platformUsersCollection';

export default function Actions() {
  // const updateUserDates = () => {
  //   let count = 0;
  //   const users = collection(currFirestore, 'platformUsers');
  //   getDocs(users).then((querySnap) => {
  //     querySnap.forEach((docSnap) => {
  //       const user = docSnap.data() as PlatformUser;
  //       count++;
  //       console.log(count, user.email);
  //       updateDoc(docSnap.ref, {
  //         dateCreated: Timestamp.fromMillis(Date.now()),
  //         dateUpdated: Timestamp.fromMillis(Date.now()),
  //       });
  //     });
  //   });
  // };

  return (
    <div className="actions-page">
      {/* <Button className="std-btn" variant="contained" color="primary" aria-label="update users" onClick={updateUserDates}>
        <span className="label">Update Users</span>
      </Button> */}
    </div>
  );
}
