import { EntityReference, EntitySchema } from '@camberi/firecms';
import { CustomFilePreviewFunc } from '../App';

export interface Product {
  name: string;
  price: number; // Interger in cents.
  description: string; // Markdown.
  ingredients: string; // Markdown.
  whenToUse: string; // Markdown.
  howToUse: string; // Markdown.
  importantSafetyInformation: string[]; // File urls.
  productInsertFiles: string[]; // File urls.
  files: string[]; // File urls.
  images: string[]; // File urls.
  vimeoVideoUrls: string[]; // Vimeo urls.
  logo: string; // File url.
  tags: string[];
  dateCreated: any;
  dateUpdated: any;
  visible: boolean;
  featured: boolean;
  isNew: boolean;
  // isOnPromo: boolean;
  promotion: EntityReference;
  relatedProducts: EntityReference[];
}

export default {
  name: 'Product',
  properties: {
    name: {
      title: 'Name',
      validation: { required: true },
      dataType: 'string',
    },
    price: {
      title: 'Price',
      validation: {
        requiredMessage: 'You must set a price between USD $0 and $1,000,000.',
        min: 0,
        max: 1_000_000,
      },
      description: 'Default price of the product',
      dataType: 'number',
    },
    relatedProducts: {
      dataType: 'array',
      title: 'Related Products',
      of: {
        dataType: 'reference',
        path: 'products',
      },
    },
    description: {
      title: 'Description',
      dataType: 'string',
      config: {
        markdown: true,
      },
    },
    ingredients: {
      title: 'Ingredients',
      dataType: 'string',
      config: {
        markdown: true,
      },
    },
    whenToUse: {
      title: 'When to use',
      dataType: 'string',
      config: {
        markdown: true,
      },
    },
    howToUse: {
      title: 'How to use',
      dataType: 'string',
      config: {
        markdown: true,
      },
    },
    logo: {
      title: 'Logo',
      description: 'The logo for the product.',
      dataType: 'string',
      config: {
        storageMeta: {
          mediaType: 'image',
          storagePath: 'dynamicImages',
          acceptedFiles: ['image/*'],
          fileName: (context: any) => {
            return context.file.name;
          },
        },
      },
    },
    images: {
      dataType: 'array',
      title: 'Images',
      description: 'The images of the product to be shown in the image carousel. The first image will be the display image.',
      of: {
        dataType: 'string',
        config: {
          storageMeta: {
            mediaType: 'image',
            storagePath: 'dynamicImages',
            acceptedFiles: ['image/*'],
            fileName: (context: any) => {
              return context.file.name;
            },
          },
        },
      },
    },
    vimeoVideoUrls: {
      dataType: 'array',
      title: 'Vimeo Videos',
      description: 'All links included here must link to vimeo videos in a format like this: https://vimeo.com/264055977',
      of: {
        dataType: 'string',
        validation: {
          matches: /https:\/\/vimeo\.com\/.*/s,
        },
      },
    },
    importantSafetyInformation: {
      title: 'Important Safety Information',
      dataType: 'array',
      description: 'Files for the product inserts.',
      of: {
        dataType: 'string',

        config: {
          Preview: CustomFilePreviewFunc,
          storageMeta: {
            storagePath: 'productFiles',
            fileName: (context: any) => {
              return context.file.name;
            },
          },
        },
      },
    },
    productInsertFiles: {
      dataType: 'array',
      title: 'Product Insert Files',
      description: 'Files for the product inserts.',
      of: {
        dataType: 'string',

        config: {
          Preview: CustomFilePreviewFunc,
          storageMeta: {
            storagePath: 'productFiles',
            fileName: (context: any) => {
              return context.file.name;
            },
          },
        },
      },
    },
    files: {
      dataType: 'array',
      title: 'Files',
      description: 'The shareable files associated with this product (brochures, images, other marketing materials etc.)',

      of: {
        dataType: 'string',

        config: {
          Preview: CustomFilePreviewFunc,
          storageMeta: {
            storagePath: 'productFiles',
            fileName: (context: any) => {
              return context.file.name;
            },
          },
        },
      },
    },
    tags: {
      dataType: 'array',
      title: 'Tags',
      description: 'Used for categorization of the product',
      of: {
        dataType: 'string',
        config: {
          enumValues: {
            companionAnimal: 'Companion Animal',
            largeAnimal: 'Large Animal',
            parasitology: 'Parasitology',
            toxicology: 'Toxicology',
            dermatology: 'Dermatology',
            jointSupport: 'Joint Support',
            painManagement: 'Pain Management',
            behavior: 'Behavior',
            renalSupport: 'Renal Support',
            gastrointestinal: 'Gastrointestinal',
            dentalCare: 'Dental Care',
            immuneSupport: 'Immune Support',
          },
        },
      },
    },
    visible: {
      title: 'Visible',
      dataType: 'boolean',
      description: 'Should this product be invisible?',
    },
    featured: {
      title: 'Featured',
      dataType: 'boolean',
      description: 'Should this product be featured on the dashboard?',
    },
    isNew: {
      title: 'Is New',
      dataType: 'boolean',
      description: 'Advertise this item with a "New!" tag?',
    },
    // isOnPromo: {
    //   title: 'Is on Promo',
    //   dataType: 'boolean',
    //   description: 'Advertise this item with an "On Promo!" tag?',
    // },
    promotion: ({ values }: any) => {
      return {
        path: 'promotions',
        dataType: 'reference',
        title: 'Related Promotion',
        description: 'If this product is on promo, specify the promotion here.',        
      };
    },
    miscText: {
      title: 'Misc Text',
      dataType: 'string',
      config: {
        markdown: true,
      },
    },
    // The difference between this and the files section is that these files can be grouped with text in the Misc section.
    miscFiles: {
      dataType: 'array',
      title: 'Misc Files',
      description: 'The shareable files associated with this product (brochures, images, other marketing materials etc.)',

      of: {
        dataType: 'string',

        config: {
          Preview: CustomFilePreviewFunc,
          storageMeta: {
            storagePath: 'productFiles',
            fileName: (context: any) => {
              return context.file.name;
            },
          },
        },
      },
    },
    dateCreated: {
      title: 'Date Created',
      dataType: 'timestamp',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      title: 'Date Updated',
      dataType: 'timestamp',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
  },
} as EntitySchema<Product>;
