import { EntitySchema } from '@camberi/firecms';
import { CustomFilePreviewFunc } from '../App';

export interface ContinuingEducation {
  name: string;
  description: string; // Markdown.
  files: string[]; // File urls.
  image: string; // File urls.
  tags: string[];
  visible: boolean;  
  dateCreated: any;
  dateUpdated: any;  
}

export default {
  name: 'Continuing Education',
  properties: {
    name: {
      title: 'Name',
      validation: { required: true },
      dataType: 'string',
    },
    description: {
      title: 'Description',
      dataType: 'string',
      config: {
        markdown: true,
      },
    },
    image: {
      title: 'Image',
      description: 'An optional picture to associate with the post.',
      dataType: 'string',
      config: {
        storageMeta: {
          mediaType: 'image',
          storagePath: 'dynamicImages',
          acceptedFiles: ['image/*'],
          fileName: (context) => {
            return context.file.name;
          },
        },
      },
    },
    files: {
      dataType: 'array',
      title: 'Files',
      validation: { required: true },
      description: 'The shareable files associated with this product (brochures, images, other marketing materials etc.)',
      of: {
        dataType: 'string',
        config: {
          Preview: CustomFilePreviewFunc,
          storageMeta: {
            storagePath: 'productFiles',
            fileName: (context: any) => {
              return context.file.name;
            },
          },
        },
      },
    },
    tags: {
      dataType: 'array',
      title: 'Tags',
      description: 'Used for categorization of the product',
      of: {
        dataType: 'string',
        config: {
          enumValues: {
            companionAnimal: 'Companion Animal',
            largeAnimal: 'Large Animal',
          },
        },
      },
    },
    visible: {
      title: 'Visible',
      dataType: 'boolean',
      description: 'Should this product be invisible?',
    },
    dateCreated: {
      title: 'Date Created',
      dataType: 'timestamp',
      autoValue: 'on_create',
      disabled: {
        hidden: true,
      },
    },
    dateUpdated: {
      title: 'Date Updated',
      dataType: 'timestamp',
      autoValue: 'on_update',
      disabled: {
        hidden: false,
      },
    },
  },
} as EntitySchema<ContinuingEducation>;
